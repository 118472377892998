import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import businessman from "images/sections/businessman.svg";
import { NewlineText } from './../components/misc/Utils';
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as ArrowRight } from "images/arrow-right-icon.svg";
import { ReactComponent as ButtonPrefix } from "images/button-prefix.svg";

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative max-w-[50%]`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 xl:w-6/12 mt-16 mb-16 md:mt-0`,
  props.textOnLeft ? tw`md:pr-12 lg:pr-16 md:order-first` : tw`md:pl-12 lg:pl-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded max-w-[85%]`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-2xl lg:text-2xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center text-justify text-sm md:text-base lg:text-base font-medium leading-relaxed text-secondary-100`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 flex inline-block tracking-wide w-[fit-content] items-center`;


export default ({
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = "Kick Start Your Exports Career",
  headingText = "Guiding New Entrepreneurs to Success.",
  description = "Navigating the intricate world of international trade can be daunting, especially for new entrepreneurs. At MyEXIM, we understand the challenges that come with starting an export business. That's why we offer tailored services and unwavering support from the very beginning.\n"
  +"Our expert team assists in the entire setup process, from obtaining necessary registrations and licenses to understanding market trends and getting leads. We provide insightful guidance to ensure your business is on solid ground, ready to take on the global market ",
  textOnLeft = false,
  buttonRounded = true,
  primaryButtonUrl = "https://app.myeximbusiness.com/Exporter",
  primaryButtonText = "Set Up Your New Export Company",
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;

  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
        <TextColumn textOnLeft={textOnLeft}>
            <Subheading>{subheading}</Subheading>
            <Heading>{headingText}</Heading>
            <Description><NewlineText text={description}/></Description>
            <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton as="a" href={primaryButtonUrl} target="_blank" css={buttonRoundedCss} style={{borderRadius:9999}}>
            <ButtonPrefix style={{ marginRight: 10, width: 30 }} />
              {primaryButtonText}
            <ArrowRight style={{ marginLeft: 10, width: 20 }} />
          </PrimaryButton>
        </div>
          </TextColumn>
          <ImageColumn>
            <Image src={businessman} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
          </ImageColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
