import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import bannerbg from "images/sections/banner-bg.svg";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as ArrowRight } from "images/arrow-right-icon.svg";
import { ReactComponent as ButtonPrefix } from "images/button-prefix.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = styled.div`
  ${tw`relative mx-2 mt-8 py-10 px-10 bg-center bg-cover bg-no-repeat`}
  background-color: #F8F8F8;
  background-image: url("${bannerbg}");
`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-4xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(
  SectionDescription
)`text-[#121212] text-center mx-auto max-w-screen-md`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 flex inline-block tracking-wide w-[fit-content] items-center justify-center`;

export default ({
  subheading = "",
  heading = "Ready To Transform Your",
  headingNext = "Trade Experience?",
  description = "We've redesigned the way businesses connect, trade, and prosper. Our platform is designed to simplify the B2B trade process by allowing buyers to post inquiries and exporters to submit quotations, creating a dynamic marketplace that thrives on efficiency and collaboration.",
  primaryButtonText = "Signup For Free",
  buttonRounded = true,
  primaryButtonUrl = "https://app.myeximbusiness.com/signup",
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;

  return (
    <>
      <Container>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Heading>{headingNext}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton as="a" href={primaryButtonUrl} target="_blank" css={buttonRoundedCss} style={{borderRadius:9999}}>
            <ButtonPrefix style={{ marginRight: 10, width: 30 }} />
            {primaryButtonText}
            <ArrowRight style={{ marginLeft: 10, width: 20 }} />
          </PrimaryButton>
        </div>
      </Container>
    </>
  );
};
