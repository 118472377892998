import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import faq from "images/sections/faq.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${faq}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

export default ({
  subheading = "",
  heading = "Get Questions ?",
  description = "Here are some frequently asked questions about my exim business from our loving customers. Should you have any other questions, feel free to reach out via the contact form below.",
  imageSrc = "",
  imageContain = false,
  imageShadow = false,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Do I need to Pay any charges for Registration and Login?",
      answer:
        "No, There is no charges involved for Registration and Login. Sign Up and Login now to explore the opportunities."
    },
    {
      question: "How this platform helps to boost my Business?",
      answer:
        "This platform simplifies the pain points in export import business. You can directly connect with required parties with out any mediators involved including us."
    },
    {
      question: "What can I do after Registration and Login to this Platform?",
      answer:
        "View Features for More Details."
    },
    {
      question: "Do we need to pay any Commission to MY EXIM BUSINESS for the enquiry raised or Quotations Received?",
      answer:
        "Absolutely No, You no need to Pay any commission and there is no middle man involved including us on dealing each other."
    },
    {
      question: "I want to become an Exporter. How this Platform helps me?",
      answer:
        "This Platform has Exclusive Feature and Expertise to help you to create Required documents with reasonable price and Exporter Login which is having Buyer Requirements to start your business Immediately."
    },
    {
      question: "My Shipment is on Hold at Destination Port due to Payment issues. How this Platform helps to solve My issue?",
      answer:
        "Yes, This platform helps you to communicate with Buyers Directly Through My Promotions and get chance to sell to other Buyers."
    },
    {
      question: "Does this platform supports making Payments between Buyers and Exporters?",
      answer:
        "No, we don’t Involved in Payments between the Parties."
    },
    {
      question: "If we are making Payments to any Party, will MY EXIM BUSINESS provides any assurance for our Payment?",
      answer:
        "No, Since we are not involving in making payment, its completely your responsibility to do due diligence before making any payments."
    },
    {
      question: "Who are the stake Holders registered in this Platform?",
      answer:
        "Buyers, Exporters, Manufacturers, Commission Agents, CHA’s, Freight Forwarders, Shipping Lines."
    },
    {
      question: "Can I view Contact Details of the Party who sends the Quotation for my Enquiry?",
      answer:
        "Yes, You can view the Company Profile with Contact Details only for the Quotations sent to your RFQ(Request For Quote)."
    },
    {
      question: "How Many Quotations I will receive for each Enquiry?",
      answer:
        "You will receive Maximum 10 quotations for your RFQ raised, so that you can compare the quotes and make right decision."
    },
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
