import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import bannerbg from "images/sections/banner-bg.svg";

const Container = styled.div`
  ${tw`relative mx-2 bg-center bg-cover bg-no-repeat`}
  background-image: url('${bannerbg}');
`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-10 md:py-10`;

const TextContent = tw.div`lg:py-8 text-center md:text-left lg:w-2/4`;
const FormContainer = tw.div`m-4 p-8 rounded shadow-2xl bg-white flex justify-center max-w-lg lg:w-2/4`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center font-bold md:text-left leading-tight text-[#121212]`;
const Description = tw.p`mt-4 text-center md:text-left font-normal leading-relaxed text-[#121212] text-2xl`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col  mx-auto md:mx-0 w-full`;
const Input = tw.input`
  mt-6 
  first:mt-0 
  border-b 
  py-3 
  focus:outline-none 
  transition 
  duration-300 
  focus:border-primary-500  /* Border color on focus */
  hocus:border-primary-500  /* Border color on hover/focus */
  text-[#626262]  /* Default text color when unfocused */
  focus:text-primary-800  /* Text color when focused */
  text-base 
  font-semibold 
  leading-8 
  placeholder-[#626262]  /* Placeholder color */
  placeholder:text-xs
`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Contact Us",
  heading = <>Get In Touch.</>,
  description = "Whether you have a query, need assistance, or want to explore partnership opportunities, our team is here to help..",
  submitButtonText = "Send Message",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [mobile, setMobile] = useState('');
  const [error, setError] = useState('');

  // RegEx to allow only numbers, optional + at start, and space/hyphen
  const mobileNumberRegEx = /^\+?\d{1,3}?[- ]?\d{7,15}$/;

  const handleChange = (e) => {
    const value = e.target.value;

    // Replace any non-numeric and non-country code characters
    const filteredValue = value.replace(/[^0-9+]/g, '');

    setMobile(filteredValue);
  };

  return (
    <Container>
      <TwoColumn>
        <TextContent>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </TextContent>
        <FormContainer>
          <Form>
            <Input type="text" name="name" placeholder="Full Name" maxLength={85} />
            <Input type="email" name="email" placeholder="Email Address" maxLength={85} />
            <Input type="text" name="mobile" placeholder="Mobile Number with Country Code (e.g. +917358725025)" value={mobile} 
            onChange={handleChange} inputMode="tel" pattern="[+0-9]*" maxLength={15} />
            <Textarea name="message" placeholder="Your Message Here" maxLength={285} />
            <SubmitButton type="submit">{submitButtonText}</SubmitButton>
          </Form>
        </FormContainer>
      </TwoColumn>
    </Container>
  );
};
