import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Modal from "react-modal";
import "slick-carousel/slick/slick.css";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as MailIcon } from "feather-icons/dist/icons/send.svg";
import { axiosInstance, BASE_API_CONFIG } from "helpers/Api";
import { NewlineText } from "./../components/misc/Utils";

const CloseModalButton = tw.button`absolute top-0 right-0 mt-4 mr-4 hocus:text-primary-500`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

const FormContainer = tw.div`lg:px-8 lg:py-4 rounded shadow-xl bg-white flex justify-center max-w-lg  border-[#F5F5F5]`;


const Form = tw.form`text-sm flex flex-col  mx-auto md:mx-0 w-full`;
const Input = tw.input`mt-2 first:mt-0 border-b py-2 focus:outline-none transition duration-300 hocus:border-primary-500 text-sm font-semibold leading-8 text-primary-800 placeholder-[#626262]`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px !important",
    transform: "translate(-50%, -50%)",
    width: 500,
    minHeight: 300,
    border: "0px !important",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .5)",
  },
};

const thanksCustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px !important",
    transform: "translate(-50%, -50%)",
    width: 500,
    minHeight: 300,
    border: "0px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .5)",
  },
};

const FormHeader = tw.div`text-black p-4 text-lg font-bold`;
const ThanksHeader = tw.div`text-black px-4 py-2 text-xl font-bold text-center`;
const ThanksDescription = tw.div`text-black px-4 py-2 text-sm text-center`;



export default ({modalIsOpen,closeModal}) => {
  const [modalType, setModalType] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data= {
      name: formData.get('name'),
      email: formData.get('email'),
      mobile: formData.get('mobile'),
      message: formData.get('message'),
      country: 'India',
      dialCode: '+91'
    }
    console.log(data);

    try{
      await axiosInstance.post(
        `${BASE_API_CONFIG.baseURL}/website-queries`,
        data
      );
      setModalType('thanks');
    }catch(error){
      console.log(error)
    }
  }
  const submitButtonText = "Submit";


  const thanksDesc =
    "Your submission has been received.\n" +
    "We will be in touch and contact you soon";
  return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalType !== "thanks" ?customStyles: thanksCustomStyles}
      >
        <CloseModalButton onClick={closeModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        {modalType !== "thanks" ? (
          <div>
            <FormHeader>Request for Demo</FormHeader>
            <FormContainer>
              <Form onSubmit={handleSubmit}>
                <Input type="text" name="name" placeholder="Full Name" />
                <Input type="email" name="email" placeholder="Email Address" />
                <Input type="text" name="mobile" placeholder="Mobile Number" />
                <Textarea name="message" placeholder="Your Message Here" />
                <SubmitButton type="submit">{submitButtonText}</SubmitButton>
              </Form>
            </FormContainer>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <MailIcon tw="w-10 h-10" />
            <ThanksHeader>Thank you</ThanksHeader>
            <ThanksDescription>
              {<NewlineText text={thanksDesc} />}
            </ThanksDescription>
          </div>
        )}
      </Modal>
  );
};
