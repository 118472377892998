import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Introduction from "sections/Introduction.js";
import UserRoles from "sections/UserRoles.js";
import Rfq from "sections/RFQ.js";
import Signup from "sections/Signup.js";
import Navbar from "sections/Navbar.js";
import Header from "sections/header.js";
import Footer from "sections/Footer.js";
import bannerHomeShip from "images/sections/banner-home-ship.svg";

const headerData = [
  {
    heading: "Empowering Your Global Trade Journey",
    description: "No Subscription, No Commission, No Mediators!",
    image: bannerHomeShip,
    primaryButtonText: "Sign Up for Free",
    subHeading: "Your Gateway to Global Trade",
  },
];

export default () => {
  return (
    <AnimationRevealPage>
      <Navbar />
      <Header data={headerData} ctaLink="https://app.myeximbusiness.com/signup" />
      <Introduction />
      <UserRoles />
      <Rfq />
      <Signup />
      <Footer />
    </AnimationRevealPage>
  );
};
