import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Navbar from "sections/Navbar.js";
import FaqHeader from "sections/FaqHeader.js";
import Footer from "sections/Footer.js";
import Signup from 'sections/Signup.js';

export default () => {
  return (
    <AnimationRevealPage>
      <Navbar/>
      <FaqHeader/>
      <Signup/>
      <Footer />
    </AnimationRevealPage>
  );
}
