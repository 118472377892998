import "slick-carousel/slick/slick.css";
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import imagesrc from "images/sections/user-roles.svg";
import { NewlineText } from "./../components/misc/Utils";
import { ReactComponent as ArrowRight } from "images/arrow-right-icon.svg";
import { ReactComponent as ButtonPrefix } from "images/button-prefix.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative flex justify-center`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 xl:w-6/12 mt-16 mb-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:pr-12 lg:pr-16 md:order-first`
    : tw`md:pl-12 lg:pl-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-2xl lg:text-2xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center text-justify text-sm md:text-base lg:text-base font-medium leading-relaxed text-secondary-100`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 flex inline-block tracking-wide w-[fit-content] items-center`;

export default ({
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = "User Roles",
  headingText = "Facilitating Seamless Trade Connections.",
  description = "At MyEXIM, we cater to diverse user roles, each vital in the global trade ecosystem. For exporters, we simplify product listings and streamline the quoting process. Buyers can post their requirements and receive tailored quotations from vetted exporters.\n" +
    "Commission agents benefit from a vast network, connecting them with manufacturers and buyers efficiently. Our platform ensures CHAs, shipping lines, and freight forwarders have transparent communication channels, making trade processes swift and straightforward. ",
  textOnLeft = false,
  primaryButtonText = "Read More",
  buttonRounded = true,
  primaryButtonUrl = "/features",
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <TextColumn textOnLeft={textOnLeft}>
            <Subheading>{subheading}</Subheading>
            <Heading>{headingText}</Heading>
            <Description>
              <NewlineText text={description} />
            </Description>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                as="a"
                href={primaryButtonUrl}
                css={buttonRoundedCss}
              >
                <ButtonPrefix style={{ marginRight: 10, width: 30 }} />{" "}
                {primaryButtonText}{" "}
                <ArrowRight style={{ marginLeft: 10, width: 20 }} />
              </PrimaryButton>
            </div>
          </TextColumn>
          <ImageColumn>
            <Image
              style={{ maxWidth: "80%" }}
              src={imagesrc}
              imageBorder={imageBorder}
              imageShadow={imageShadow}
              imageRounded={imageRounded}
            />
          </ImageColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
