import axios from 'axios';

export const BASE_API_CONFIG = {
  baseURL: 'http://54.198.95.202:3001',
  requestTimeOut: 40000,
  headers: { 'Content-Type': 'application/json' }
};

export const axiosInstance = axios.create({
  baseURL: BASE_API_CONFIG.baseURL,
  timeout: BASE_API_CONFIG.requestTimeOut,
  headers: BASE_API_CONFIG.headers
});
