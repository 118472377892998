import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Navbar from "sections/Navbar.js";
import Contact from "sections/ContactForm.js";
import ContactDetails from "sections/ContactDetails.js";

import Footer from "sections/Footer.js";
import Signup from 'sections/Signup.js';

export default () => {
  return (
    <AnimationRevealPage>
      <Navbar/>
      <Contact/>
      <ContactDetails/>
      <Signup/>
      <Footer />
    </AnimationRevealPage>
  );
}
