import React from "react";
import tw from "twin.macro";
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
import { css } from "styled-components/macro"; //eslint-disable-line

const Header = tw(HeaderBase)`max-w-none px-4 py-4 shadow-sm`;


export default ({
  buttonRounded = true,
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const ActiveLink = (page) => window.location.pathname === page && tw`border-primary-500 text-primary-500!`;
  const navLinks = [
    <NavLinks key={1}>
      <NavLink css={ActiveLink('/')} href="/">Home</NavLink>
      <NavLink css={ActiveLink('/features')} href="/features">Features</NavLink>
      <NavLink css={ActiveLink('/exporter')} href="/exporter">Want to become an Exporter?</NavLink>
      <NavLink css={ActiveLink('/faq')} href="/faq">FAQ</NavLink>
      <NavLink css={ActiveLink('/contact')} href="/contact">Contact Us</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <NavLink href="https://app.myeximbusiness.com/signup" target="_blank" tw="lg:ml-12!">
        Sign Up
      </NavLink>
      <PrimaryLink css={buttonRoundedCss} href="https://app.myeximbusiness.com/signin" target="_blank">
        Login
      </PrimaryLink>
    </NavLinks>
  ];
  return (
    <>
      <Header links={navLinks} />
    </>
  );
};
