import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { featureData } from "data/FeatureData";
import FeatureSlider  from "sections/FeatureSlider.js";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 w-full text-center`;
const Heading = tw(SectionHeading)`w-full text-4xl text-[#121212]`;
const Description = tw(
  SectionDescription
)`w-full text-center text-lg text-[#121212] font-medium`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/2`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`text-center flex-shrink-0`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

// {subheading && <Subheading>{subheading}</Subheading>}

export default () => {
  const handleClickScroll = (name) => {
    console.log(name)
    const element = document.getElementById(name);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <FeatureSlider onClick={handleClickScroll}/>
      {featureData.map((feature) => {
        return (
          <Container id={feature.name}>
            <ThreeColumnContainer>
              <img src={feature.icon} alt="" style={{ width: 150 }} />
              <Heading>{feature.name}</Heading>
              {<Description>{feature.description}</Description>}
              <VerticalSpacer />
              {feature.cards.map((card, i) => (
                <Column key={i}>
                  <Card>
                    <div className="imageContainer">
                      <img src={card.icon} alt="" />
                    </div>
                    <span className="textContainer">
                      <span className="title">{card.title}</span>
                      <p className="description">{card.description}</p>
                    </span>
                  </Card>
                </Column>
              ))}
            </ThreeColumnContainer>
          </Container>
        );
      })}
      ;
    </div>
  );
};
