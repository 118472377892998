import exporters from 'images/sections/exporter.svg';
import exportAgent from 'images/featureIcons/export-agent.svg';
import exportCollabManf from 'images/featureIcons/export-collab-manf.svg';
import exportCollabCha from 'images/featureIcons/export-collaboration-cha.svg';
import exportFreight from 'images/featureIcons/export-freight.svg';
import exportPropose from 'images/featureIcons/export-propose.svg';
import exportShipping from 'images/featureIcons/export-shipping.svg';

import buyer from 'images/sections/buyer.svg';
import buyerChooseQuote from 'images/featureIcons/buyer-choose-quote.svg';
import buyerEvalute from 'images/featureIcons/buyer-evaluate.svg';
import buyerQuote from 'images/featureIcons/buyer-quote.svg';
import buyerRfq from 'images/featureIcons/buyer-rfq.svg';

import manufacturer from 'images/sections/manufacturer.svg';
import manufSupply from 'images/featureIcons/manf-supply.svg';
import manfSubmitQuote from 'images/featureIcons/manf-submit-quote.svg';

import cha from 'images/sections/cha.svg';
import chaBank from 'images/featureIcons/cha-bank.svg';
import chaQuote from 'images/featureIcons/cha-quote.svg';


import shipping from 'images/sections/shipping.svg';
import shipOperate from 'images/featureIcons/ship-operate.svg';
import shipPropose from 'images/featureIcons/ship-propose.svg';

import freight from 'images/sections/freight.svg';
import freightOffers from 'images/featureIcons/freight-offers.svg';
import freightNetwork from 'images/featureIcons/freight-network.svg';

import commissionAgent from 'images/sections/commissionAgent.svg';
 


export const featureData = [
    {
        name:'Exporters',
        description:'Exporters are the backbone of global trade, and MyEXIM empowers them to connect with essential partners. They can:',
        icon:exporters,
        cards:[
            {
                title:'Submit Proposals to Buyers',
                description: 'Exporters can present competitive proposals directly to buyers in response to their requirements, showcasing their products and terms.',
                icon:exportPropose
            },
            {
                title:'Collaborate with CHA',
                description:'Exporters work closely with Custom House Agents (CHA) to navigate government regulations efficiently, ensuring compliance and smooth clearance.',
                icon:exportCollabCha
            },
            {
                title:'Collaborate with Manufacturers',
                description:'Exporters collaborate with manufacturers to determine product prices, ensuring competitive and attractive offerings to potential buyers.',
                icon:exportCollabManf
            },
            {
                title:'Request Fares from Shipping Lines',
                description:'Exporters have the capability to directly request shipping fares from Shipping Lines, enabling them to assess transportation costs accurately.',
                icon:exportShipping
            },
            {
                title:'Work with  Commission Agents',
                description:'Exporters can seek product prices from Commission Agents, leveraging their networks to explore a variety of options.',
                icon:exportAgent
            },
            {
                title:'Get Fares from Freight Forwarders',
                description:'Exporters can also approach Freight Forwarders to inquire about shipping fares, exploring different options for efficient and cost-effective shipping solutions.',
                icon:exportFreight
            }
        ]
    },
    {
        name:'Buyers',
        description:'Buyers From across the world seeking diverse product options can find their ideal suppliers on MyEXIM. They can:',
        icon:buyer,
        cards:[
            {
                title:'Submit RFQ',
                description: 'Buyers initiate the process by posting their Request for Quotation (RFQ) on the MyEXIM platform, detailing their specific product requirements and expectations.',
                icon:buyerRfq
            },
            {
                title:'Receives Multiple Quotations',
                description:'Buyers receive diverse quotations from multiple exporters, offering a range of products, prices, and terms.',
                icon:buyerQuote
            },
            {
                title:'Evaluates Options',
                description:'Buyers can thoroughly evaluate the received quotations, comparing product quality, prices, delivery terms, and additional services.',
                icon:buyerEvalute
            },
            {
                title:'Chooses the Best Quote',
                description:'Buyers have the flexibility to select the quote that best suits their needs, ensuring a tailored and cost-effective procurement process for their business.',
                icon:buyerChooseQuote
            }
        ]
    },
    {
        name:'Manufacturers',
        description:'Manufacturers play a vital role in the export industry, and MyEXIM enhances their reach and efficiency. They can:',
        icon:manufacturer,
        cards:[
            {
                title:'Direct Supply to Exporters',
                description: 'MyEXIM facilitates manufacturers in establishing direct partnerships with exporters, streamlining the supply chain for efficient transactions and reliable business relationships.',
                icon:manufSupply
            },
            {
                title:'Submit Quote To Exporters',
                description:'Manufacturers have the opportunity to submit competitive quotes directly to exporters. This process ensures that manufacturers can showcase their products and pricing, enabling them to attract potential buyers effectively.',
                icon:manfSubmitQuote
            }
        ]
    },
    {
        name:'Commision Agents',
        description:'Commission Agents act as intermediaries between exporters and producers. They can:',
        icon:commissionAgent,
        cards:[
            {
                title:'Networks with Manufacturers',
                description: 'MyEXIM facilitates manufacturers in establishing direct partnerships with exporters, streamlining the supply chain for efficient transactions and reliable business relationships.',
                icon:exportCollabManf
            },
            {
                title:'Submits Quotes to Exporters',
                description:'Leveraging these connections, they can submit multiple proposals to the exporter. These proposals encompass varied options and competitive pricing, offering exporters a diverse and attractive selection.',
                icon:buyerQuote
            }
        ]
    },
    {
        name:'Custom House Agents (CHA)',
        description:'Custom House Agents navigate complex customs regulations, and MyEXIM streamlines their interactions. They can:',
        icon:cha,
        cards:[
            {
                title:'Government Mediation',
                description: 'CHAs act as intermediaries, mediating between businesses and government bodies, particularly port officials. They navigate intricate processes and regulations on behalf of exporters, ensuring compliance and seamless clearance.',
                icon:chaBank
            },
            {
                title:'Comprehensive Proposal Submission',
                description:'CHAs submit detailed proposals encompassing the entire shipment clearance process, from port procedures to fulfilling government regulations. Their quotes include all necessary steps, ensuring exporters have a clear understanding of the entire customs process.',
                icon:chaQuote
            }
        ]
    },
    {
        name:'Shipping Lines',
        description:'Shipping Lines are vital in global logistics, and MyEXIM supports their collaborations. They can:',
        icon:cha,
        cards:[
            {
                title:'Operate Ships and Vessels',
                description: 'Shipping Lines are professional companies specializing in the ownership and operation of ships and vessels. They manage the logistics of sea transport, ensuring the safe and timely delivery of goods.',
                icon:shipOperate
            },
            {
                title:'Submit Shipping Proposals',
                description:'Shipping Lines have the capability to submit comprehensive proposals and quotes directly to exporters. These proposals detail the shipping process, including costs and schedules, providing exporters with essential information to make informed decisions about transportation.',
                icon:shipPropose
            }
        ]
    },
    {
        name:'Freight Forwarders',
        description:'Freight Forwarders optimize shipping logistics, and MyEXIM enhances their efficiency. They can:',
        icon:shipping,
        cards:[
            {
                title:'Extensive Shipping Network',
                description: 'Freight Forwarders are intermediaries with extensive connections within the shipping industry. They have a wide network of contacts among various shipping companies.',
                icon:freightNetwork
            },
            {
                title:'Submit Multiple Shipping Offers',
                description:'Leveraging their connections, Freight Forwarders obtain multiple shipping offers for exporters. This diverse range of options allows exporters to choose the most suitable and cost-effective shipping solution for their specific requirements.',
                icon:freightOffers
            }
        ]
    },
    
]