import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import MapImg from "images/sections/map.svg";
import { NewlineText } from './../components/misc/Utils';

const Container = tw.div`relative bg-[#F8F8F8]`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-10 md:py-10 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative flex justify-center`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);


const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-2xl lg:text-2xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-8 text-center text-justify text-sm md:text-base lg:text-base font-medium leading-relaxed text-secondary-100`;

export default ({
  subheading = "Introduction",
  heading = (
    <>
      MyEXIM Business, Your Gateway to Global Trade!
    </>
  ),
  description = "Welcome to My Exim Business where Trade connections transform into opportunities, and where every trader finds the perfect match for their business needs. we understand that success in international trade is built on strong relationships. we believe in the power of connections to drive international trade forward. Whether you're an Exporter, Manufacturer, Customs House Agent(CHA), Shipping line, Freight Forwarder, or Buyer, our platform is designed to be your central hub for collaboration, growth, and seamless trade.",
  imageSrc = MapImg,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  textOnLeft = true,
}) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image style={{maxWidth:'80%'}} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
